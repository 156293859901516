<template>
  <div class="pad-home">
    <HeaderBar
      title="设置"
      :showLeft="true"
    ></HeaderBar>
    <div class="setting">
      <div class="setting-list">
        <div class="setting-list-item">
          <div class="setting-list-item-title">服务价格</div>
          <div class="setting-icon"></div>
        </div>
        <div class="setting-list-item">
          <div class="setting-list-item-title">服务协议</div>
          <div class="setting-icon"></div>
        </div>
        <!-- <div class="setting-list-item">
          <div class="setting-list-item-title">服务热线</div>
          <div class="setting-list-item-content">1111</div>
        </div> -->
        <div class="setting-list-item">
          <div class="setting-list-item-title">当前版本</div>
          <div class="setting-list-item-content">V{{ version }}</div>
        </div>
      </div>

      <div
        class="setting-logout"
        @click.stop.prevent="logout"
      >注销账户</div>
    </div>
  </div>
</template>

<script>
import HeaderBar from "@/components/headerbar/headerbar";
import { mapMutations } from "vuex";
import { CUSTOM_VERSION } from "@/config/";
import { logOut } from "@/service/";
// import wxChat from "../../components/wxchat/wxChat";
import { getStore, clearLoginStore, clearStore } from "../../lib/storage";

export default {
  name: "setting",
  components: {
    HeaderBar,
  },
  data() {
    return {
      version: CUSTOM_VERSION,
    };
  },
  methods: {
    ...mapMutations(["SET_MSG_LIST"]),
    async logout() {
      let res = await logOut(JSON.parse(getStore("userInfo")).ID);
      if (res.code == 1) {
        console.log(res);
        // 断开极光IM
        // if (this.$chat.isLogin()) {
        // this.$chat.loginout();
        this.SET_MSG_LIST([]);
        // }

        clearStore();
        this.$router.push("/login");
        // setTimeout(() => {
        //   if (!getStore("wx")) {
        //     _this.$router.replace("/oauth");
        //   } else if (getStore("wx") && getStore("token")) {
        //     _this.$router.replace("/home");
        //   }
        // }, 500);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/mixin.less";

.pad-home {
  padding-top: 94px;
}
.setting {
  &-list {
    padding: 0 30px;
    &-item {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      height: 100px;
      border-bottom: 2px solid #eee;

      &-title {
        font-size: 30px;
        font-family: "PingFangSC-Medium";
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        line-height: 42px;
      }

      &-content {
        font-size: 30px;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 42px;
      }
    }
  }

  &-icon {
    .size(12px, 20px);
    background: url("../../assets/back@2x.png") 0 0 no-repeat;
    background-size: 12px 20px;
  }

  &-logout {
    width: 690px;
    height: 76px;
    margin: 60px auto;
    background: rgba(51, 51, 51, 1);
    border-radius: 8px;
    font-size: 30px;
    font-family: "PingFangSC-Medium";
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: 76px;
    text-align: center;
  }
}
</style>

